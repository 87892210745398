import ALL_PASSENGERS from '@/graphql/passengers/query/getAllPassengersAutocomplete.gql'

const passengers = ref<{label: string; value: string}[]>([])
const passengers2 = ref<{label: string; value: string}[]>([])
const key = ref(0)
const key2 = ref(0)
const firstTime = ref(true)
const firstTime2 = ref(true)

export function passengersAutocomplete(params: any) {
  const apolloQuery = ALL_PASSENGERS
  const variables = {
    search: '%' + params.search + '%',
    orderBy: [
      {
        column: 'FIRST_NAME',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchPassengersCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await passengersAutocomplete({ search: queryString })
    cb(results?.getAllPassengers?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchPassengersCb')
  }
}

export function getSearchPassengers(entityPassenger: any, entityPassengers: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchPassengers('', entityPassenger, entityPassengers)
  }

  return passengers.value
}

export function getSearchPassengers2(entityPassenger: any, entityPassengers: any) {
  if (firstTime2.value === true) {
    firstTime2.value = false
    searchPassengers('', entityPassenger, entityPassengers)
  }

  return passengers2.value
}

export async function searchPassengers(queryString: any, entityPassenger: any, entityPassengers: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await passengersAutocomplete({ search: queryString })
    const remotePassengers = results?.getAllPassengers?.data?.map((i: any) => {
      return {
        label: `${i.first_name} ${i.last_name}` ?? '',
        value: i.uuid
      }
    }) ?? []
    const passengersToAdd: { label: any; value: any }[] = []
    entityPassengers?.forEach((j: any) => {
      if (!remotePassengers.find((k: any) => k.value === j.uuid)) {
        passengersToAdd.push({
          label: `${j.first_name} ${j.last_name}` ?? '',
          value: j.uuid
        })
      }
    })
    if (entityPassenger && !remotePassengers.find((k: any) => k.value === entityPassenger.uuid)) {
      passengersToAdd.push({
        label: `${entityPassenger.first_name} ${entityPassenger.last_name}` ?? '',
        value: entityPassenger.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    passengers.value = [...remotePassengers, ...passengersToAdd]
    firstTime.value = false

    if (entityPassenger) {
      getSearchPassengers(entityPassenger, entityPassengers)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchPassengers')
  }
}

export async function searchPassengers2(queryString: any, entityPassenger: any, entityPassengers: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await passengersAutocomplete({ search: queryString })
    const remotePassengers = results?.getAllPassengers?.data?.map((i: any) => {
      return {
        label: `${i.first_name} ${i.last_name}` ?? '',
        value: i.uuid
      }
    }) ?? []
    const passengersToAdd: { label: any; value: any }[] = []
    entityPassengers?.forEach((j: any) => {
      if (!remotePassengers.find((k: any) => k.value === j.uuid)) {
        passengersToAdd.push({
          label: `${j.first_name} ${j.last_name}` ?? '',
          value: j.uuid
        })
      }
    })
    if (entityPassenger && !remotePassengers.find((k: any) => k.value === entityPassenger.uuid)) {
      passengersToAdd.push({
        label: `${entityPassenger.first_name} ${entityPassenger.last_name}` ?? '',
        value: entityPassenger.uuid
      })
    }
    if (refresh) {
      key2.value = 0
    }
    if (key2.value === 0) {
      key2.value = setCustomKeyVariable()
    }
    passengers2.value = [...remotePassengers, ...passengersToAdd]
    firstTime2.value = false

    if (entityPassenger) {
      getSearchPassengers(entityPassenger, entityPassengers)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchPassengers2')
  }
}

export function getPassengerKey () {
  return key.value
}

export function getPassengerKey2 () {
  return key2.value
}

export function initialPassengers () {
  passengers.value = []
  passengers2.value = []
  firstTime.value = true
  firstTime2.value = true
  key.value = 0
  key2.value = 0
}